import { module } from 'constant'
import { createSelector } from 'selectors'

const { selector } = module.crosstable

export const getCrosstableSelector = () => createSelector<HTMLElement>({ selector: selector.own })
const getContentSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.content })
const getLoaderSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.loader })
const getStaticSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.static })
const getRowsHeadSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.headRow })
const getRowsContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.rows })
const getRowsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.row },
		true,
	)
const getActiveRowsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalActiveRows })
const getActiveColumnsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalActiveCols })
const getModalLegendSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalLegend })
const getModalConfigSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalConfig })
const getModalConfigQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.modalConfigQuestions })
const getHeadColsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.headCol },
		true,
	)
const getHeadColSplitItemsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.headColSplitItem },
		true,
	)
const getColsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.col },
		true,
	)
const getColsSplitsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.colSplit },
		true,
	)
const getColsSplitItemsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.colSplitItem },
		true,
	)
const getStaticColsSelector = (scope?: HTMLElement, value?: string) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.staticCol, value },
		true,
	)
const getStaticRowsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.staticRow },
		true,
	)
const getStaticColsSplitsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.staticColSplit },
		true,
	)
const getFirstRowSelector = (scope?: HTMLElement) => createSelector<HTMLElement>({ scope, selector: selector.row })
const getSearchInputSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>({ scope, selector: selector.searchInput })
const getSearchableItemsSelector = (scope?: HTMLElement) =>
	createSelector(
		{ scope, selector: selector.searchableItem },
		true,
	)
const getEditorQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>({ scope, selector: selector.editorQuestions })
const getEditorAvailableQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorAvailableQuestions })
const getEditorAssignedQuestionsSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorAssignedQuestions })
const getEditorQuestionnaireSelectSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorQuestionnaireSelect })
const getEditorQuestionnaireSubmitSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.editorQuestionnaireSubmit })

const getFilterTabSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.filter.filterTab })

const getQuestionsTabSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questions.tab })

const getActiveQuestionsContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.filter.activeQuestionsContainer })

const getActiveRowsContainerSelectorQuestions = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questions.activeRowsContainer })

const getActiveColumnsContainerSelectorQuestions = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questions.activeColumnsContainer })

const getQuestionsFilterContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questionsFilterContainer })

const getQuestionsQuestionsContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questions.questionsContainer })

const getQuestionsSearchInputContainerSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questions.questionsSearchInputContainer })

const getItemByQuestionIdSelector = (questionId: string, scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: `${selector.questionId}='${questionId}'` })

const getQuestionsSearchInputSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.questions.questionsSearchSelector })

const getFilterSearchInputSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.filter.filterSearchSelector })

const getCodeToggleSwitchSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.codeToggleSwitch })

const getCodeItemsSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.codeItem },
		true,
	)

const getValuesContainersSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.valuesContainer },
		true,
	)

const getMasterCheckboxSelector = (scope: HTMLElement) =>
	createSelector<HTMLInputElement>({ scope, selector: selector.masterCheckbox })

const getLastHeaderColsSelector = (scope: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: selector.lastHeaderColumn },
		true,
	)

const getColSplitItemsByColumnSelector = (scope: HTMLElement, columnNumber: number) => {
	return createSelector<NodeListOf<HTMLElement>>(
		{ scope, selector: `${selector.colSplitItem}='${columnNumber}'` },
		true,
	)
}

const getSettingsTabSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.settings.tab })

const getWeightedSwitchSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.settings.weightedSwitch })

const getUnweightedCountSwitchSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.settings.unweightedCountSwitch })

const getTotalSwitchSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.settings.totalSwitch })

const getSubTotalSwitchSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.settings.subtotalSwitch })

const getActiveTooltipSelector = (scope?: HTMLElement) =>
	createSelector<HTMLElement>({ scope, selector: selector.tooltip.tooltipContent })

export const getCrosstableSelectors = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return {
		content: getContentSelector(scope),
		loader: getLoaderSelector(scope),
		staticContainer: getStaticSelector(scope),
		staticColsSplits: getStaticColsSplitsSelector(scope),
		staticRows: getStaticRowsSelector(scope),
		rowsContainer: getRowsContainerSelector(scope),
		rows: getRowsSelector(scope),
		headRows: getRowsHeadSelector(scope),
		activeRows: getActiveRowsSelector(scope),
		activeColumns: getActiveColumnsSelector(scope),
		modalLegend: getModalLegendSelector(scope),
		modalConfig: getModalConfigSelector(scope),
		modalConfigQuestions: getModalConfigQuestionsSelector(scope),
		searchInput: getSearchInputSelector(scope),
		searchableItems: getSearchableItemsSelector(scope),
		firstRow: getFirstRow(),
		firstRowCols: getFirstRowCols(),
		valuesContainers: getValuesContainers(),
	}
}

export const getCrosstableEditorSelectors = () => {
	const scope = getEditorQuestionsSelector()

	if (!scope) return null
	return {
		editorQuestions: scope,
		editorAvailableQuestions: getEditorAvailableQuestionsSelector(scope),
		editorAssignedQuestions: getEditorAssignedQuestionsSelector(scope),
		editorQuestionnaireSelect: getEditorQuestionnaireSelectSelector(scope),
		editorQuestionnaireSubmit: getEditorQuestionnaireSubmitSelector(scope),
	}
}

export const getCrossTableFilterSelectors = () => {
	const scope = getFilterTabSelector()

	if (!scope) return null
	return {
		filterTab: scope,
		activeQuestionsContainer: getActiveQuestionsContainerSelector(),
		questionsFilterContainer: getQuestionsFilterContainerSelector(),
		codeToggleSwitch: getCodeToggleSwitchSelector(scope),
	}
}

export const getCrossTableQuestionsSelectors = () => {
	const scope = getQuestionsTabSelector()

	if (!scope) return null
	return {
		tab: scope,
		activeRowsContainer: getActiveRowsContainerSelectorQuestions(),
		activeColumnsContainer: getActiveColumnsContainerSelectorQuestions(),
		questionsQuestionsContainer: getQuestionsQuestionsContainerSelector(),
		questionsSearchInputContainer: getQuestionsSearchInputContainerSelector(),
		codeToggleSwitch: getCodeToggleSwitchSelector(scope),
	}
}

export const getCrossTableSettingsSelectors = () => {
	const scope = getSettingsTabSelector()

	if (!scope) return null
	return {
		weightedSwitch: getWeightedSwitchSelector(scope),
		unweightedCountSwitch: getUnweightedCountSwitchSelector(scope),
		totalSwitch: getTotalSwitchSelector(scope),
		subtotalSwitch: getSubTotalSwitchSelector(scope),
	}
}

export const getFirstRow = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	const rowsContainer = getRowsContainerSelector(scope)

	if (!rowsContainer) return null
	const firstRow = getFirstRowSelector(rowsContainer)

	if (!firstRow) return null
	return firstRow
}

export const getFirstRowCols = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	const firstRow = getFirstRowSelector(scope)

	if (!firstRow) return null
	const firstRowCols = getColsSelector(firstRow)

	if (!firstRowCols) return null
	return firstRowCols
}

export const getHeadCols = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return getHeadColsSelector(scope)
}

export const getHeadColSplitItems = (scope: HTMLElement) => {
	if (!scope) return null
	return getHeadColSplitItemsSelector(scope)
}

export const getCols = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return getColsSelector(scope)
}

export const getColsSplits = (scope: HTMLElement) => {
	if (!scope) return null
	return getColsSplitsSelector(scope)
}

export const getColsSplitItems = (scope: HTMLElement) => {
	if (!scope) return null
	return getColsSplitItemsSelector(scope)
}

export const getStaticCols = (value?: string | number) => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	if (value) return getStaticColsSelector(scope, String(value))
	return getStaticColsSelector(scope)
}

export const getItemByQuestionId = (questionId: string, scope: HTMLElement) => {
	if (!scope) return null
	return getItemByQuestionIdSelector(questionId, scope)
}

export const getQuestionsSearchInput = (scope: HTMLElement) => {
	if (!scope) return null
	return getQuestionsSearchInputSelector(scope)
}

export const getFilterSearchInput = (scope: HTMLElement) => {
	if (!scope) return null
	return getFilterSearchInputSelector(scope)
}

export const getCodeItems = (scope: HTMLElement) => getCodeItemsSelector(scope)

export const getValuesContainers = () => {
	const scope = getCrosstableSelector()

	if (!scope) return null
	return getValuesContainersSelector(scope)
}

export const getCodeToggleSwitch = (scope: HTMLElement) => {
	if (!scope) return null
	return getCodeToggleSwitchSelector(scope)
}

export const getMasterCheckbox = (scope: HTMLElement) => {
	if (!scope) return null
	return getMasterCheckboxSelector(scope)
}

export const getLastHeaderCols = () => {
	const scope = getRowsHeadSelector()
	if (!scope) return null
	return getLastHeaderColsSelector(scope)
}

export const getColSplitItemsByColumn = (columnNumber: number) => {
	const scope = getRowsContainerSelector()
	if (!scope) return null
	return getColSplitItemsByColumnSelector(scope, columnNumber)
}

export const getActiveTooltip = () => {
	const scope = getCrosstableSelector()
	if (!scope) return null
	return getActiveTooltipSelector(scope)
}
