import { payloadHelper } from 'helper'
import * as filterSelectors from 'selectors'
import { Events } from './events'
import { Modules } from './modules'

type InitMainModulesProps = {
	isAjax?: boolean
}

export const Setup = () => {
	const initResizer = (redrawCharts: boolean) => {
		const { abstractResizer, charts, figures } = Modules
		abstractResizer.init({
			onInit: async () => {
				await abstractResizer.automaticHeight()
				if (redrawCharts) {
					await charts.update()
					await charts.init({})
					figures.resize()
				}
				abstractResizer.destroyResizerLoading()
				abstractResizer.destroyLoading()
			},
			onResizeComplete: async () => {
				await abstractResizer.automaticHeight()
				await charts.update()
				figures.resize()
			},
			onWindowResize: async () => {
				abstractResizer.fixHeight()
				await abstractResizer.automaticHeight()
				await charts.update()
			},
		})
	}

	const initMainModules = (props?: InitMainModulesProps) => {
		Modules.__deprecated.init(props && props.isAjax)
		Modules.alertDashboard.init(props && props.isAjax)
		Modules.alertDetail.init()
		Modules.alertAppeal.init()
		Modules.blockHelps.init()
		Modules.creator.init()
		Modules.crossTable.init(props && props.isAjax)
		Modules.drawer.init({ onClose: { filters: Modules.filter.submitFilters } })
		Modules.fileManager.init()
		Modules.filter.init({ onReset: [Modules.drawer.closeActiveDrawers], isAjax: props && props.isAjax })
		Modules.header.init({ shouldScrolledModifier: !filterSelectors.getActiveFilters() })
		Modules.menu.init()
		Modules.tracking.init(props && props.isAjax)
		Modules.fulltext.init()
		Modules.feedbackDetail.init()
	}

	const handleAjaxComplete = (payload: any) => {
		initMainModules({ isAjax: true })

		if (payloadHelper.ExistAutomaticHeight(payload)) {
			Modules.abstractResizer.fixHeight()
		}

		initResizer(payload && payload.redrawCharts)

		if (payloadHelper.ExistRedrawCharts(payload)) {
			Modules.abstractResizer.initContextMenu()
		}

		if (payloadHelper.ExistUrl(payload)) {
			window.history.pushState('', '', payload.url)
		}

		if (payloadHelper.ReinitCarousel(payload)) {
			setTimeout(() => {
				Modules.tracking.init()
			}, 200)
		}

		if (payloadHelper.TriggerCampaignChange(payload)) {
			Modules.tracking.triggerCampaignSelectChange()
		}
	}

	const handleBeforeAjaxConfirm = (_: any, settings: any) => {
		if (!settings.nette) {
			return null
		}
		const question = settings.nette.el.data('confirm')
		if (question) {
			// eslint-disable-next-line no-restricted-globals,no-undef,no-alert
			return confirm(question)
		}
		return null
	}

	const handleDOMLoad = () => {
		initMainModules()
		initResizer(true)
		Modules.figures.resize()
	}

	const handleLoad = () => {}

	const handleNetteExtLoad = () => {}

	const apply = () => {
		const events = Events()
		events.onAjaxComplete(handleAjaxComplete)
		events.onBeforeAjaxConfirm(handleBeforeAjaxConfirm)
		events.onDOMLoad(handleDOMLoad)
		events.onLoad(handleLoad)
		events.onNetteExtLoad(handleNetteExtLoad)
	}

	return {
		apply,
	}
}
