export const constant = {
	selector: {
		target: 'js-m-loader',
		mainLoader: 'data-main-loader',
	},
	style: {
		loader: 'm-loader',
		loaderShape: 'm-loader__shape',
	},
	styleModifier: {
		loaderAbsolute: 'm-loader--absolute',
		loaderDatatable: 'm-loader--datatable',
	},
}
