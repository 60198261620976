import { module } from 'constant'
import { createSelector } from 'selectors'

const { selector } = module.feedbackDetail

const getFeedbackDetailStatusSelector = () => createSelector<HTMLElement>({ selector: selector.status })
const getPinStateSelect = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>({ scope, selector: selector.statePin }, true)

const getCheckboxStateSelect = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLInputElement>>({ scope, selector: selector.stateCheckbox }, true)

export const getFeedbackDetailSelectors = () => {
	const scope = getFeedbackDetailStatusSelector()
	if (!scope) return null
	return {
		checkboxStateItems: getCheckboxStateSelect(scope),
		pinStateItems: getPinStateSelect(scope),
	}
}
