import { useEvent, useState } from 'hooks'
import { getFeedbackDetailSelectors } from 'selectors'

type State = {
	checkboxStateItems?: NodeListOf<HTMLInputElement>
	pinStateItems?: NodeListOf<HTMLElement>
}

export const FeedbackDetail = (() => {
	const { setState, state } = useState<State>()

	const checkToCorrespondingCheckbox = (id: string, checkboxItems: NodeListOf<HTMLInputElement>) => {
		for (let i = 0; i < checkboxItems.length; i++) {
			if (!checkboxItems[i]) continue
			checkboxItems[i].checked = true
			if (checkboxItems[i].id === `status-${id}`) {
				const event = new Event('change')
				checkboxItems[i].dispatchEvent(event)
				break
			}
		}
	}

	const uncheckAllCheckboxes = (checkboxItems: NodeListOf<HTMLInputElement>) => {
		for (let i = 0; i < checkboxItems.length; i++) {
			if (!checkboxItems[i]) continue
			checkboxItems[i].checked = false
		}
	}

	const handlePinClick = () => {
		const { pinStateItems } = state
		if (!pinStateItems) return

		for (let i = 0; i < pinStateItems.length; i++) {
			if (!pinStateItems[i]) continue
			const id = pinStateItems[i].id.split('-')[1]
			const click = useEvent(pinStateItems[i], 'click')

			click.register(() => {
				const { checkboxStateItems } = state
				if (!checkboxStateItems) return

				uncheckAllCheckboxes(checkboxStateItems)
				checkToCorrespondingCheckbox(id, checkboxStateItems)
			})
		}
	}

	const handleInitSelectors = () => {
		setState({
			...getFeedbackDetailSelectors(),
		})
	}

	const init = () => {
		handleInitSelectors()
		handlePinClick()
	}

	return {
		init,
	}
})()
