import { modifier } from 'constant'
import { cn } from 'utils'

import { constant } from './constant'
import { selectors } from './selector'

type CreateOptions = {
	absolute?: boolean
	datatable?: boolean
}

const ANIMATION_DELAY = 200

export const Loader = (() => {
	const create = (scope: HTMLElement, { absolute, datatable }: CreateOptions) => {
		const fragment = document.createDocumentFragment()
		const loader = document.createElement('div')
		const loaderShape = document.createElement('div')

		cn.addClass(loader, constant.style.loader)
		if (absolute) cn.addClass(loader, constant.styleModifier.loaderAbsolute)
		if (datatable) cn.addClass(loader, constant.styleModifier.loaderDatatable)

		cn.addClass(loader, constant.selector.target)
		cn.addClass(loaderShape, constant.style.loaderShape)

		loader.appendChild(loaderShape)
		fragment.appendChild(loader)

		scope.appendChild(fragment)
	}

	const destroy = (scope: HTMLElement) => {
		const t = selectors.getTarget(scope)

		if (!t) return
		const { parentNode } = t

		if (!parentNode) return
		cn.addClass(t, modifier.hidden)
		setTimeout(() => parentNode.removeChild(t), ANIMATION_DELAY)
	}

	const destroyAll = () => {
		const targets = selectors.getAllTargets()

		if (!targets) return
		const { length } = targets

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const t = targets[i] as HTMLElement

			if (!t) continue
			const { parentNode } = t

			if (!parentNode) continue
			cn.addClass(t, modifier.hidden)
			setTimeout(() => parentNode.removeChild(t), ANIMATION_DELAY)
		}
	}

	const showMain = () => {
		const mainLoader = selectors.getMainLoader()
		if (!mainLoader) {
			return
		}
		cn.removeClass(mainLoader, modifier.hidden)
	}

	const hideMain = () => {
		const mainLoader = selectors.getMainLoader()
		if (!mainLoader) {
			return
		}
		cn.addClass(mainLoader, modifier.hidden)
	}

	return {
		create,
		destroy,
		destroyAll,
		showMain,
		hideMain,
		constant,
		selectors,
	}
})()
