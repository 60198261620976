import { useState } from '@/hooks'
import { appModels } from '@/models'

export const GlobalService = (() => {
	const { state, setState } = useState<appModels.AppState>({ lang: 'en', filter: {} })

	const registerAppConfig = () => {
		setTimeout(() => {
			setState(window.appConfig)
		}, 10)
	}

	const getState = () => {
		return state
	}

	const getLang = () => state.lang || 'en'

	const getFilter = (key?: string) => (key ? state.filter[key] : state.filter)

	const setFilter = (key: string, value: any) => {
		if (!state.filter[key]) {
			state.filter[key] = {}
		}
		if (typeof state.filter[key] === 'object') {
			state.filter[key] = { ...state.filter[key], ...value }
			return
		}
		state.filter[key] = value
	}

	return {
		registerAppConfig,
		getState,
		getLang,
		getFilter,
		setFilter,
	}
})()
