import { module } from 'constant'
import { createSelector, getHeader } from 'selectors'

const getFilterHandlerSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.filter.selector.handler }, true)

const getFilterResetSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.filter.selector.reset }, true)

const getActiveFiltersSelector = (scope?: HTMLElement) =>
	createSelector({ scope, selector: module.filter.selector.active })

export const getFilterSelectors = () => {
	const header = getHeader()

	if (!header) return null
	return {
		filterHandlers: getFilterHandlerSelector(header),
		filterResets: getFilterResetSelector(),
		activeFilters: getActiveFiltersSelector(),
	}
}

const getFilterSelectionSelector = (value?: string) =>
	createSelector<HTMLElement>({ selector: module.filter.selector.filterValue, value })

export const getFilterSelectionName = (value: string) => {
	const filterSelection = getFilterSelectionSelector(value)
	if (!filterSelection) return null
	return filterSelection
}

export const getActiveFilters = () => {
	const activeFilters = getActiveFiltersSelector()

	if (!activeFilters) return null
	return activeFilters
}

export const getFilterSelection = (value: string) =>
	createSelector<HTMLElement>({ selector: module.filter.selector.filterSelection, value })

export const getFilterInputs = (value: string) =>
	createSelector<NodeListOf<HTMLInputElement>>({ selector: module.filter.selector.filterInput, value }, true)

// TODO: Deprecated
export const getFilterSelectionSpan = (scope: HTMLElement) => createSelector<HTMLElement>({ selector: 'p span', scope })
